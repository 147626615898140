import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: () => import('../layouts/Home'),
    redirect: '/home',
    children: [
      {
        path: '/home',
        name: 'home',
        component: () => import('../views/Home'),
      },
      {
        path: '/editor/:mdId',
        name: 'editor',
        component:() => import('../views/Editor')
      },
      {
        path: '/preview/:mdId',
        name: 'preview',
        component:() => import('../views/Editor/preview')
      },
      {
        path: '/profile',
        name: 'profile',
        component:() => import('../views/Profile')
      },
      {
        path: '/userList',
        name: 'userList',
        component:() => import('../views/UserList')
      },
      {
        path: '/editLog/:id',
        name: 'editLog',
        component:() => import('../views/History')
      },
      {
        path: '/test',
        name: 'test',
        component:() => import('../views/test')
      },
    ]
  },
  {
    path: '/login',
    // name: 'loginLayout',
    component: () => import('../layouts/Login'),
    children: [
      {
        path: '/login',
        name: 'login',
        component: () => import('../views/Login/Login')
      },
      {
        path: '/register',
        name: 'register',
        component: () => import('../views/Login/Register')
      }
    ]
  }
]


const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  if(to.path ==='/login'){
    localStorage.removeItem("user");
    localStorage.removeItem("token");
  }
  let user = localStorage.getItem("token");
  if(!user && (to.path !== '/login' && to.path !== '/register')){
    next({ path: '/login' })
  }else{
    next();
  }
})

export default router
