import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null,
    token: localStorage.getItem('token') ? localStorage.getItem('token') : '',
  },
  getters: {
    getUser: state => {
      return state.user
    }
  },
  mutations: {
    setUser(state, payload) {
      const { token } = payload
      state.token = token;
      state.user = payload;
      localStorage.setItem("user", JSON.stringify(payload));
      localStorage.setItem("token", token);
    },
    updateUser(state, payload) {
      state.user = {...state.user, ...payload}
      localStorage.setItem("user", JSON.stringify(state.user));
    },
    logout(state) {
      state.token = '';
      state.user = null;
      localStorage.removeItem("user");
      localStorage.removeItem("token");
    },
  },
  actions: {
  },
  modules: {
  }
})
